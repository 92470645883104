function get(obj, path): any {
    return path.split('.').reduce((r, e) => {
        if (!r) return r;
        else if (r[e] == false) return false;
        else return r[e] || undefined;
    }, obj);
}

function isEmpty(o): any {
    // If any ambiguity caused in previous work. Remember the change of !o.
    if (!o || typeof o !== 'object') return true;
    else return !Object.keys(o).length;
}

function build(a, b, o = null, prev = ''): any {
    return Object.keys(a).reduce(
        (r, e) => {
            const path = prev + (prev ? '.' + e : e);
            const bObj = get(b, path);
            const value = a[e] === bObj;

            if (typeof a[e] === 'object') {
                if (isEmpty(a[e]) && isEmpty(bObj)) {
                    if (!r[e]) r[e] = true;
                } else if (!bObj && isEmpty(a[e])) {
                    r[e] = value;
                } else {
                    r[e] = build(a[e], b, r[e], path);
                }
            } else {
                r[e] = value;
            }
            return r;
        },
        o ? o : {}
    );
}

export const _compareArrays = (a, b) => {
    const o = build(a, b);
    return build(b, a, o);
};
