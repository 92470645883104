import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnChanges {
    @Input() placeholder = 'Search';
    @Input() wrapperClass = 'bg-white';
    @Input() inputClass = '';
    @Input() style = 'height: 40px; border-radius: 8px';
    @Input() autocomplete = 'on';
    @Input() resetSearch: boolean;
    @Output() search: EventEmitter<string> = new EventEmitter();
    @Output() change: EventEmitter<string> = new EventEmitter();
    isInputFocused = false;
    searchValue = '';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resetSearch && changes.resetSearch.currentValue && this.searchValue != '') {
            this.searchValue = '';
            this.search.emit(this.searchValue);
        }
    }
    onChange(event: any) {
        this.searchValue = event.target.value;
        this.search.emit(event.target.value);
    }
    onInputFocus() {
        this.isInputFocused = true;
    }
    onInputBlur() {
        this.isInputFocused = false;
    }
    onAnyChange(event: any) {
        this.change.emit(event.target.value);
    }
}
