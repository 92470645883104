// colworx-ak start
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { format } from 'date-fns';
import { POC_DOC_STATUS } from '../helpers/constants';
import { ColDef } from 'ag-grid-community';
import { CommentsCellRenderer } from '../modules/pp-accounts-documents/pp-documents/poc-comments-cell-render/poc-comments-cell-render';
import { ROUTER_UTILS } from '../helpers/utils';
import { ToasterService } from './toaster.service';

@Injectable({
    providedIn: 'root',
})
export class PpDocumentsService {
    constructor(private toastService: ToasterService) { }

    private dataSource = new BehaviorSubject<any>(this.getDataFromStorage());

    currentData = this.dataSource.asObservable();

    updateData(data: any) {
        this.dataSource.next(data);
        localStorage.setItem('patientData', JSON.stringify(data));
    }

    formatToTitleCase(name: string): string {
        const formattedName = name.trim();
        return formattedName
            ? formattedName
                .split(' ')
                .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')
            : 'N/A';
    }

    somethingWentWrong() {
        this.toastService.show({
            title: 'Error',
            body: `Something Went Wrong`,
            type: 'error',
        });
    }

    getPocListHeaders(): ColDef[] {
        return [
            {
                headerName: 'Patient',
                field: 'patient',
                valueGetter: (v) => {
                    return this.formatToTitleCase(v?.data?.patientName || '');
                },
                sortable: true,
                headerCheckboxSelection: false,
                checkboxSelection: false,
                resizable: true,
                sortingOrder: ['asc', 'desc'],
                colId: 'patientName',
            },
            {
                headerName: 'Doc Type',
                valueGetter: (v) => `${v?.data?.documentType}`,
                resizable: true,
            },
            {
                headerName: 'Physician',
                field: 'physician',
                sortable: true,
                sortingOrder: ['asc', 'desc'],
                cellClass: 'd-flex align-items-center justify-content-center overflow-hidden',
                headerClass: 'header-center ag-list-header-bg-blue',
                valueGetter: (v) => {
                    return this.formatToTitleCase(v?.data?.physicianName || 'N/A');
                },
                resizable: true,
                colId: 'physicianName',
            },
            {
                headerName: 'Status',
                field: 'status',
                headerClass: 'header-center ag-list-header-bg-blue',
                cellClass: 'd-flex align-items-center justify-content-center',
                cellRenderer: (params) => {
                    let badges = '';
                    const status = params.data.documentStatus;
                    let style = 'ml-1 border badge p-2 px-3 rounded-pill ';
                    if (status) {
                        if (status.includes(POC_DOC_STATUS.REJECTED))
                            style += 'bg-danger-a10 text-danger border-danger';
                        else if (status.includes(POC_DOC_STATUS.SIGNED))
                            style += 'border-success bg-success-a10 text-success';
                        else if (status.includes(POC_DOC_STATUS.PENDING))
                            style += 'border-warning-500 bg-warning-a10 text-warning-500';
                        badges += `<span class='${style} d-inline-block align-middle'>${status == POC_DOC_STATUS.PENDING ? POC_DOC_STATUS.SENT : status
                            }</span>`;
                    } else {
                        style += 'bg-danger-a10 text-danger border-danger';
                        badges += `<span class='${style} d-inline-block align-middle'>${status}</span>`;
                    }
                    return `<span class="d-block">${badges}</span>`;
                },
                resizable: true,
            },
            {
                headerName: '',
                field: 'dateDaysAgo',
                sortingOrder: ['asc', 'desc'],
                colId: 'dueDate', // Sorting by 'dueDate'
                cellClass: 'd-flex align-items-center justify-content-center text-gray-400',
                cellRenderer: (params) => {
                    const docDate = new Date(params.data?.dueDate);
                    docDate.setHours(0, 0, 0, 0); // Calculate the difference in days
                    const diffDays = params.data?.diffDays;
                    // Format the date to "MMM dd, yyyy"
                    const formattedDate = format(docDate, 'MMM dd, yyyy');

                    let dayText;
                    if (diffDays < 0) {
                        dayText = '0 day'; // Render '0 day' for negative diffDays
                    } else if (diffDays === 0) {
                        dayText = 'Today';
                    } else if (diffDays === 1) {
                        dayText = '1 day';
                    } else {
                        dayText = `${diffDays} days`;
                    }

                    // Return the formatted string "Aug 30, 2024 (Today/1 day/3 days)"
                    return `${formattedDate} (${dayText})`;
                },
                resizable: true,
            },
            {
                headerName: 'Comments',
                resizable: true,
                headerClass: 'header-center ag-list-header-bg-blue',
                cellClass: 'd-flex align-items-center justify-content-center',
                field: 'comments',
                cellStyle: () => {
                    return { overflow: 'unset' };
                },
                cellRenderer: CommentsCellRenderer,
                cellRendererParams: (params) => {
                    return {
                        disabled: params.data.comment ? false : true,
                    };
                },
            },
        ];
    }

    getSubUrl(event) {
        return event.documentType == 'Daily Note'
            ? ROUTER_UTILS.config.documentationViewMode.dailyNoteDoc.root
            : event.documentType == 'Progress Note'
                ? ROUTER_UTILS.config.documentationViewMode.progressNoteDoc.root
                : event.documentType == 'Updated Plan of Care'
                    ? ROUTER_UTILS.config.documentationViewMode.updatePlanOfCare.root
                    : event.documentType == 'Recertification'
                        ? ROUTER_UTILS.config.documentationViewMode.reCertificationDoc.root
                        : event.documentType == 'Discharge Note'
                            ? ROUTER_UTILS.config.documentationViewMode.dischargeNote.root
                            : event.documentType == 'Evaluation'
                                ? ROUTER_UTILS.config.documentationViewMode.evalDoc.root
                                : event.documentType == 'Addendum'
                                    ? ROUTER_UTILS.config.documentationViewMode.addendum.root
                                    : null;
    }

    sortDocuments(docData: any[], sortField: string, sortOrder: string): any[] {
        return docData.sort((a, b) => {
            let fieldA = a[sortField];
            let fieldB = b[sortField];

            if (sortField === 'dueDate') {
                // Handle date sorting
                fieldA = new Date(a.dueDate).getTime();
                fieldB = new Date(b.dueDate).getTime();
            } else if (sortField === 'patientName') {
                // Handle sorting for patient name (case-insensitive)
                fieldA = a.patientName ? a.patientName.toLowerCase() : '';
                fieldB = b.patientName ? b.patientName.toLowerCase() : '';
            } else if (sortField === 'physicianName') {
                // Handle sorting for physician names (case-insensitive)
                fieldA = a.physicianName ? a.physicianName.toLowerCase() : 'N/A';
                fieldB = b.physicianName ? b.physicianName.toLowerCase() : 'N/A';
            }

            // Sorting logic (asc or desc)
            if (sortOrder === 'asc') {
                return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;
            } else {
                return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0;
            }
        });
    }

    sortData(event: any, accounts: any[]): { sortField: string; sortOrder: string; sortedData: any[] } {
        const sort = event.columnApi.getColumnState().filter((column) => column.sort !== null);
        let sortField = '';
        let sortOrder = '';

        if (sort.length) {
            sortField = sort[0].colId;
            sortOrder = sort[0].sort;
        }

        // Sort the existing data instead of hitting the API
        const sortedData = this.sortDocuments(accounts, sortField, sortOrder);
        return { sortField, sortOrder, sortedData };
    }
    private getDataFromStorage() {
        const data = localStorage.getItem('patientData');
        return data ? JSON.parse(data) : null;
    }
}
