/* eslint-disable spellcheck/spell-checker */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { OrganizationManagementService, ToasterService } from '@app/services';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {
    editIcon = './../../../../../assets/icons/edit-icon.svg';
    @Output() goBack = new EventEmitter<boolean>();
    @Output() checkboxStatus = new EventEmitter<{ billingChecked: boolean; clockChecked: boolean, isSaving: boolean }>();
    @Input() isEditMode = false;
    @Input() backTitle = 'Go Back';
    @Input() editingOrgId = '';
    @Input() org: any= {};
    @Input() config: any= {};
    loading = true;
    isDataSaving = false;
    data: any[] = [];
    headerSizes = [
        { title: 'Activity', size: '22vw', padding: '2vw' },
        { title: 'Unit*', size: '8vw', padding: '2vw' },
        { title: 'Billable*', size: '12vw', padding: '2vw' },
        { title: 'Rate', size: '12vw', padding: '2vw' },
        { title: 'End Date', size: '12vw', padding: '2vw' },
        { title: '', size: '5vw', padding: '2vw' },
    ];

    public SKELETON_CSS = {
        CELL: {
            width: '96%',
            height: '3.4em',
            margin: '0 2em 0.1em 2em',
        },
    };
    items = { unit: null };

    units = ['By hour', 'By mile', 'By session'];
    billOptions = ['Billable', 'Non-Billable'];

    buttons: { text: string; isSelected: boolean; isDisabled: boolean }[];
    id = '';

    form: FormGroup;
    conceptRehabURL = '';
    isEditing = false;
    facilitiesOfOrganization: string[];
    billOption='';
    clockOption='';
    billingOptions = [{ text: 'Enable contract therapy invoicing option', isChecked: false, isDisabled: false }];
    clockOptions = [{ text: 'Enable system clock in & out functionality', isChecked: false, isDisabled: false }];
    currentLoggedInOrganizationId = '';
    constructor(
        private router: Router,
        private fb: FormBuilder,
        private _organizationService: OrganizationManagementService,
        private toasterService: ToasterService,
        private store: Store<any>,

    ) {}

    ngOnInit(): void {
        if(this.editingOrgId){
          this.billingOptions[0].isChecked = this.org?.config?.enableTherapyInvoice;
          this.clockOptions[0].isChecked = this.org?.config?.enableClock;
        }
        else{
            this.billingOptions[0].isChecked = this.config?.enableTherapyInvoice;
          this.clockOptions[0].isChecked = this.config?.enableClock;
        }
        this.form = this.fb.group({
            rows: this.fb.array([]),
        });

        const url = window.location.href;
        this.isEditing = url.includes('edit');
        this.id = this.editingOrgId;
        this.billingOptions[0].isDisabled = this.isEditMode ? false : true;
        this.clockOptions[0].isDisabled = this.isEditMode ? false : true;
        
     this.store
     .select('user')
     .pipe(take(1))
     .subscribe((data) => {
         this.currentLoggedInOrganizationId = data?.user?.organization
     });
    }

    get rowForms() {
        return this.form.get('rows') as FormArray;
    }
    
    handleCancel() {
            this.isEditMode = false;
            this.goBack.emit(true);
    }
    onCheckboxChange(){
         this.checkboxStatus.emit({
      billingChecked: this.billingOptions[0].isChecked,
      clockChecked: this.clockOptions[0].isChecked,
      isSaving: false
  });
    }
handleContinue() {
    this.checkboxStatus.emit({
        billingChecked: this.billingOptions[0].isChecked,
        clockChecked: this.clockOptions[0].isChecked,
        isSaving: true
    });
}
handleSave(){
    this.isEditMode = false;
    this.billingOptions[0].isDisabled = this.isEditMode ? false : true;
    this.clockOptions[0].isDisabled = this.isEditMode ? false : true;
    this.org.config.enableTherapyInvoice = this.billingOptions[0].isChecked;
    this.org.config.enableClock = this.clockOptions[0].isChecked;
    this._organizationService
            .updateOrganization(this.editingOrgId, this.org)
            .pipe(take(1))
            .subscribe(
                (result: any) => {
                    if (result.success) {
                        this.toasterService.show({ title: 'Success', body: result.message, type: 'success' });
                        if(this.editingOrgId === this.currentLoggedInOrganizationId){
                            this._organizationService?.configUpdated$.next(result);
                        }
                    } else {
                        this.toasterService.show({ title: 'Failure', body: result.message, type: 'error' });
                    }
                },
                (err) => {
                    this.toasterService.show({ title: 'Failure', body: err, type: 'error' });
                }
            );
}
updateEditMode(){
    this.isEditMode = true;
    this.billingOptions[0].isDisabled = this.isEditMode ? false : true;
    this.clockOptions[0].isDisabled = this.isEditMode ? false : true;
}
}