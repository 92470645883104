/* eslint-disable spellcheck/spell-checker */
export const enum ENotes {
    DailyNote = "Daily Note",
    ProgressNote = "Progress Note",
    DischargeNote = "Discharge Note",
    UpdatedPlanOFCare = "Updated Plan of Care",
    Recertification = "Recertification",
    Evaluation = "Evaluation",
    Addendum = "Addendum"
}

export enum LIST_ORDER {
    ASC = 'ASC',
    DES = 'DES',
}

export enum StatusEnum {
    Active = 'Active',
    Discharged = 'Discharged',
    Open = 'Open',
    Closed = 'Closed',
}

export enum DATE_STATUS {
    Expired = 'Expired',
    ExpiringSoon = 'Expiring Soon',
    NotExpiringSoon = 'Not Expiring Soon',
}

export enum USER_TABS {
    Active = 'active',
    Terminated = 'terminated',
    Invited = 'invited',
}
